import { SeeqNames } from '@/main/app.constants.seeqnames';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { getOriginLabel, getOriginUrl } from '@/utilities/http.utilities';
import { headlessCaptureMetadata } from '@/utilities/screenshot.utilities';

export function requestOriginInterceptor(config: SeeqAxiosRequestConfig) {
  if (config.headers) {
    config.headers[SeeqNames.API.Headers.RequestOrigin] = headlessRenderMode()
      ? headlessCaptureMetadata().category ?? ''
      : 'Analysis';
    config.headers[SeeqNames.API.Headers.RequestOriginURL] = getOriginUrl();
    config.headers[SeeqNames.API.Headers.RequestOriginLabel] = getOriginLabel();
  }
  return config;
}
